:root[data-theme="dark"] {
    --colors-text-base: #ffffff;
    --colors-text-muted: rgba(255, 255, 255, 0.3);

    --colors-backgroundColor: #171717;
    --colors-notificationBarColor: #1d1d1d;
    --colors-borderColor: var(--colors-semantic-secondary-200);

    --colors-semantic-primary-100: #d0eefe;
    --colors-semantic-primary-200: #a2d8fd;
    --colors-semantic-primary-300: #73befa;
    --colors-semantic-primary-400: #50a4f6;
    --colors-semantic-primary-500: #197cf1;
    --colors-semantic-primary-600: #1260cf;
    --colors-semantic-primary-700: #0c47ad;
    --colors-semantic-primary-800: #07328b;
    --colors-semantic-primary-900: #042373;

    --colors-semantic-secondary-10: #002249;
    --colors-semantic-secondary-100: #212529;
    --colors-semantic-secondary-200: #343a40;
    --colors-semantic-secondary-300: #495057;
    --colors-semantic-secondary-400: #6c757d;
    --colors-semantic-secondary-500: #adb5bd;
    --colors-semantic-secondary-600: #ced4da;
    --colors-semantic-secondary-700: #dee2e6;
    --colors-semantic-secondary-800: #e9ecef;
    --colors-semantic-secondary-900: #f8f9fa;

    --colors-semantic-success-100: #cbfccf;
    --colors-semantic-success-200: #98f9ab;
    --colors-semantic-success-300: #62ee8b;
    --colors-semantic-success-400: #3bdd7b;
    --colors-semantic-success-500: #05c765;
    --colors-semantic-success-600: #03ab67;
    --colors-semantic-success-700: #028f64;
    --colors-semantic-success-800: #01735c;
    --colors-semantic-success-900: #005f55;

    --colors-semantic-warning-100: #fff7cd;
    --colors-semantic-warning-200: #ffed9b;
    --colors-semantic-warning-300: #ffe16a;
    --colors-semantic-warning-400: #ffd545;
    --colors-semantic-warning-500: #ffc107;
    --colors-semantic-warning-600: #dba005;
    --colors-semantic-warning-700: #b78103;
    --colors-semantic-warning-800: #936402;
    --colors-semantic-warning-900: #7a4f01;

    --colors-semantic-danger-100: #fde8dd;
    --colors-semantic-danger-200: #fbccbb;
    --colors-semantic-danger-300: #f4a797;
    --colors-semantic-danger-400: #e9837a;
    --colors-semantic-danger-500: #db5050;
    --colors-semantic-danger-600: #bc3a45;
    --colors-semantic-danger-700: #9d283c;
    --colors-semantic-danger-800: #7f1933;
    --colors-semantic-danger-900: #690f2e;

    --components-cta-backgroundColor: #222222;

    --components-mobile-store-button-backgroundColor: #454545;
    --components-mobile-store-button-backgroundColorHover: #626262;
    --components-mobile-store-button-textColor: #ffffff;

    --components-navbar-logoColor: #ffffff;
    --components-navbar-textColor: rgba(255, 255, 255, 0.5);
    --components-navbar-textColorHover: rgba(255, 255, 255, 0.7);

    --components-skeleton-baseColor: #333333;
    --components-skeleton-highlightColor: #444444;

    --components-footer-backgroundColor: #083d7d;

    --pages-otc-backgroundColor: #282828;
    --pages-blog-latest-article-background: none;
}
