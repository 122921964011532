:root[data-theme="light"] {
    --colors-text-base: #333333;
    --colors-text-muted: #00000040;

    --colors-backgroundColor: #ffffff;
    --colors-notificationBarColor: #ffffff;
    --colors-borderColor: var(--colors-semantic-secondary-200);

    --colors-semantic-primary-100: #d0eefe;
    --colors-semantic-primary-200: #a2d8fd;
    --colors-semantic-primary-300: #73befa;
    --colors-semantic-primary-400: #50a4f6;
    --colors-semantic-primary-500: #197cf1;
    --colors-semantic-primary-600: #1260cf;
    --colors-semantic-primary-700: #0c47ad;
    --colors-semantic-primary-800: #07328b;
    --colors-semantic-primary-900: #042373;

    --colors-semantic-secondary-10: #f7fbff;
    --colors-semantic-secondary-100: #f8f9fa;
    --colors-semantic-secondary-200: #e9ecef;
    --colors-semantic-secondary-300: #dee2e6;
    --colors-semantic-secondary-400: #ced4da;
    --colors-semantic-secondary-500: #adb5bd;
    --colors-semantic-secondary-600: #6c757d;
    --colors-semantic-secondary-700: #495057;
    --colors-semantic-secondary-800: #343a40;
    --colors-semantic-secondary-900: #212529;

    --colors-semantic-success-100: #d3fae8;
    --colors-semantic-success-200: #aaf6da;
    --colors-semantic-success-300: #7ae4c7;
    --colors-semantic-success-400: #55c9b4;
    --colors-semantic-success-500: #26a69a;
    --colors-semantic-success-600: #1b8e8e;
    --colors-semantic-success-700: #136e77;
    --colors-semantic-success-800: #0c5160;
    --colors-semantic-success-900: #073c4f;

    --colors-semantic-warning-100: #fff7cd;
    --colors-semantic-warning-200: #ffed9b;
    --colors-semantic-warning-300: #ffe16a;
    --colors-semantic-warning-400: #ffd545;
    --colors-semantic-warning-500: #ffc107;
    --colors-semantic-warning-600: #dba005;
    --colors-semantic-warning-700: #b78103;
    --colors-semantic-warning-800: #936402;
    --colors-semantic-warning-900: #7a4f01;

    --colors-semantic-danger-100: #fee8dc;
    --colors-semantic-danger-200: #fdccb9;
    --colors-semantic-danger-300: #faa996;
    --colors-semantic-danger-400: #f5887a;
    --colors-semantic-danger-500: #ef5450;
    --colors-semantic-danger-600: #cd3a43;
    --colors-semantic-danger-700: #ac283b;
    --colors-semantic-danger-800: #8a1933;
    --colors-semantic-danger-900: #720f2e;

    --components-cta-backgroundColor: #f6f8fa;

    --components-mobile-store-button-backgroundColor: #e5e5e5;
    --components-mobile-store-button-backgroundColorHover: #cccccc;
    --components-mobile-store-button-textColor: #7f7f7f;

    --components-navbar-logoColor: var(--colors-semantic-primary-500);
    --components-navbar-textColor: rgba(0, 0, 0, 0.5);
    --components-navbar-textColorHover: rgba(0, 0, 0, 0.7);

    --components-skeleton-baseColor: #f3f3f3;
    --components-skeleton-highlightColor: #ecebeb;

    --components-footer-backgroundColor: var(--colors-semantic-primary-500);

    --pages-otc-backgroundColor: #fafafa;
    --pages-blog-latest-article-background: linear-gradient(180deg, rgba(243, 240, 250, 0) 0%, #f3f0fa 100%);
}
