@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;

    font-family: "Manrope", sans-serif !important;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;

    font-feature-settings: "tnum" on, "lnum" on;
}

a {
    color: inherit;
    text-decoration: none;
}

.grecaptcha-badge {
    display: none;
}

@layer utilities {
    .animated-underline {
        background-image: linear-gradient(#33333300, #33333300), linear-gradient(to right, #d0eefe, #07328b);
        background-size: 100% 2px, 0 2px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: all 0.3s ease;
    }
    .animated-underline:hover,
    .animated-underline:focus {
        background-size: 0 2px, 100% 2px;
    }
}

.react-swipeable-view-container {
    z-index: 8;
    display: none;
    background-color: unset;
}

@media only screen and (max-width: 767px) {
    .react-swipeable-view-container {
        display: unset;
    }
}
